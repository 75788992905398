import { environment } from '@/environments/environment'
import useJwt from '@/auth/jwt/useJwt'

const services = [
  // Servicios vista cancelacion retiros
  { name: 'getPickupsCancelData', url: `${environment.puconApiUrl}/cancelation-management/pickups/{uuid}`, method: 'get'},
  { name: 'cancelPickup', url: `${environment.puconApiUrl}/cancelation-management/pickups/{uuid}`, method: 'put'},
  // Servicios vista evaluación de retiros
  { name: 'getEvaluationData', url: `${environment.puconApiUrl}/pickup/{uuid}/data-and-statuses`, method: 'get'},
  { name: 'evaluatePickup', url: `${environment.puconApiUrl}/pickup/{uuid}/evaluate`, method: 'put'}

]

export default class PublicPickupsService {
  constructor (context) {
    this.context = context
  }
  async callService (name, queryParams = {}, params = {}, config = {}) {
    const service = services.filter(service => service.name === name)[0]
    return useJwt.genericService(service, queryParams, params, config)
  }
  
  async callMultipleServices (callArray, byName = false) {
    const servicesArray = callArray.map(call => ({ service: services.filter(service => service.name === call.name)[0], ...call }))
    return useJwt.genericMultipleService(servicesArray, byName)
  }
}